/* Common */
a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

/* Login-Password Remember Part */
.logindiv {
  width: 28%;
  margin: auto;
  text-align: center;
  padding-top: 100px;
  height: 100vh;
}

.logindiv input,
label {
  width: 90%;
}

.logindiv input {
  border: 1px solid #e5e6e7;
  width: 95%;
  margin: auto;
  padding: 3px 8px;
}
.logindiv input:focus {
  border: 1px solid rgb(139, 167, 139);
  outline: none;
}
.logindiv img {
  animation: pulse 5s infinite;
}

.login-link {
  text-decoration: none;
  color: #337ab7;
  margin-bottom: 8px;
}

@keyframes pulse {
  0% {
    width: 10%;
    height: 10%;
    opacity: 0.1;
  }
  100% {
    width: 60%;
    height: 90%;
    opacity: 1;
  }
}

.logindiv form {
  background-color: white;
  padding: 20px;
  width: 90%;
  margin: 10px auto;
}

.logindiv p {
  color: white;
  margin-top: 10px;
  font-size: 13px;
  font-weight: 400;
}

.btn-send-password {
  background: #0d3b6c;
  opacity: 0.9;
  color: white;
  margin-bottom: 10px !important;
  transition: 1s;
}

.btn-send-password:hover {
  color: white;
  opacity: 1;
}

/* Back SideBar */

@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";

.active{
  background-color: white;
  color: #0d3b6c!important;
}

.navbar {
  padding: 15px 10px;
  background: #fff;
  border: none;
  border-radius: 0;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-dropdown-part {
  text-align: center;
  margin-bottom: 7px;
  width: 100%;
  cursor: pointer;
}

.changepassword:hover {
  color: #393a3b !important;
}

.changepassword {
  font-size: 12px;
  font-weight: 400;
  color: #676a6c;
}

.bg-transparent {
  background-color: transparent !important;
}
.setting-bg:hover {
  color: white !important;
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}

.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}

.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}

#sidebar {
  min-width: 200px;
  max-width: 200px;
  background: #0d3b6c;
  color: #fff;
}

#sidebar.active {
  display: none;
}

.dropdown-toggle:hover {
  background-color: white;
  color: #7386d5;
}

#sidebar .sidebar-header {
  padding: 20px;
  background: #0d3b6c;
}

#sidebar ul.components {
  padding: 20px 10px;
  border-bottom: 1px solid #0d3b6c;
  font-size: 15px;
}

#sidebar ul p {
  color: #fff;
  padding: 10px;
}

#sidebar ul li a {
  padding: 10px;
  font-size: 1.1em;
  display: block;
}

#sidebar ul li a:hover {
  color: #7386d5;
  background: #fff;
}

#sidebar ul li.active > a,
a[aria-expanded="true"] {
  color: #fff;
  background: rgba(13, 59, 108, 0.5);
}

a[data-toggle="collapse"] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  background: #0d3b6c;
}

ul.CTAs {
  padding: 20px;
}

ul.CTAs a {
  text-align: center;
  font-size: 0.9em !important;
  display: block;
  border-radius: 5px;
  margin-bottom: 5px;
}

a.download {
  background: #fff;
  color: #7386d5;
}

a.article,
a.article:hover {
  background: #6d7fcc !important;
  color: #fff !important;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content {
  width: 100%;
  padding: 0px!important;
  min-height: 100vh;
  transition: all 0.3s;
}

.head-part {
  display: flex;
  padding: 0;
}

.open-close-button {
  color: white!important;
  background: #0d3b6d;
  height: 40px;
}

.dropdown-responsive {
  display: none;
}

.prtl-span {
  font-weight: 700;
  font-size: 28px;
  color: #0d3b6d;
}

/*BackHeader*/
.page-name-part {
  display: flex;
  padding: 14px 1%;
  background: white;
  color: #66727e;
}

.page-name-part h2 {
  font-size: 24px;
  padding-top: 1%;
  margin-left: 14px;
  font-weight: 400;
}

.link {
  top: 50%;
  display: table-cell;
  vertical-align: middle;
}

.page-name-part .link{
  padding-top: 1%;
  margin-left: 5px;
}

.page-name-part .link:hover{
  color: #0d3b6d;
}
/* --------------- */

/* Back Normal Body Page */
.back-body{
  border:  0.05px solid #0d3b6d;
  width: 97%;
  margin:15px auto;
}

.back-body-header{
  width: 100%;
  padding: 10px;
  background-color: #0d3b6d;
  color: white;
  display: table;
}

.back-body label{
  text-align: right;
}

.right-part-button {
  color: white!important;
  text-align: center;
  background: #0d3b6d;
  border: 1px solid #0d3b6d;
  padding: 6px 10px;
  font-weight: 400;
}

.right-part-button:hover {
  background: #042d5a;
  border: 1px solid #042d5a;
}

.right-part-button i {
  margin-right: 3.5px;
  font-size: 13px;
}

/* Back Datatable Body Page */

.dataTables_length,
.dataTables_filter {
  width: 50%;
}

.dataTables_length label {
  text-align: left !important;
}

.dataTable td{
  padding-inline: 18px!important;
}

.number-parts{
  border: 0.05px solid #042e5a;
  border-radius: 5px 5px 0px 0px;
  width:33%;
  margin-right: 0.5%;
}
/* Home Page */
.parts-header{
  padding: 20px 1.5%;
  background-color: #042e5a;
  color: white;
  font-size: 18px;
}

.parts-body{
  width: 95%;
  border: 0.05px solid #ec7424;
  margin:15px auto;
}


.parts-body-header{
  width: 100%;
  padding: 10px;
  background-color: #ec7424;
  color: white;
  display: table;
}

.padding-div-back{
  padding: 20px 10px;
}

/* Navbar */

.navbar-hp{
  background-color: #042e5a!important; 
  color: white!important;
}

.navbar-img{
  width: 140px;
  height: 60px;
  margin-left: 30px;
  margin-top: 6px;
}

.navbar-title{
  width: 40%;
  text-align: center;
  font-size: 35px;
  font-style: italic;
  font-weight:500;
  display: table;
  cursor: context-menu;
}

.navbar-title div{
  top: 50%;
  display: table-cell;
  vertical-align: middle;
}

.navbar-text{
  width: 30%;
  text-align: right;
  display: table;
  font-size: 18px;
  font-weight:400;
}

.navbar-text img{
  width: 60px;
  height: 60px;
}

.div-red,.div-yellow{
  max-width: 150px;
  min-width: 110px;
  text-align: center;
  color:white;
  padding-block: 3.5px;
  font-weight: 500;
}

.div-yellow{
  background: #e0c826;
}

.div-red{
  background: #c20202;
}

/* Table Buttons */
.edit-button,
.delete-button,
.success-button,
.detail-button{
    font-size: 14px;
    color:white!important;
    padding: 5px 10px;
}

.edit-button{
    background-color:#23c6c8;
    border:1px solid #23c6c8;
}

.delete-button{
    background-color:#ed5565;
    border:1px solid #ed5565;
    margin-left: 4px;
}

.success-button{
    background-color:green;
    border:1px solid green;
}
.detail-button{
    background-color: #1c84c6;
    border:1px solid #1c84c6;
    margin-right: 4px;
}


/*For Modal*/
.modal-header-part {
  border-bottom: 3px solid #042d5a;
  color: #363f4d;
  margin-bottom: 5px;
  width: 95%;
  padding: 5px 0;
}

.modal-header-part-span {
  font-weight: 400;
  font-size: 22px;
  margin-top: 10px;
  width: 100%;
}

/*For Popup Table*/
.ndt td,
.ndt th,
.ndt tr {
  width: 33.3%;
}
.homepage-table {
  width: 100%;
}

.homepage-table tr:nth-child(n + 2) {
  border-top: 1px solid #ddd;
}

.homepage-table tr:nth-child(n + 2):hover {
  background-color: #657279;
}
.homepage-table tr:nth-child(n + 2):hover td,
.homepage-table tr:nth-child(n + 2):hover i {
  color: white;
}

.tr-head th {
  font-weight: bold;
  color: #7a7a7a !important;
}

.homepage-th,
.homepage-td,
.coming-m td {
  padding: 8px;
}

.tb tr:nth-child(3) td:nth-child(3),.tb tr:nth-child(6) td:nth-child(2){
  background-color: #eb1a1a;
  color: white;
}

.homepage-td,
.coming-m td {
  color: black;
  line-height: 1.42857143;
  font-weight: 400;
  font-style: normal;
}
.homepage-td {
  font-size: 18px;
}
/*For Scroll*/
.parts-body-parts{
  display: table;
}
.thead, .tbody tr, .thead-upcoming, .tbody-upcoming tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.tbody{
  max-height: 400px;
  overflow-y: scroll;
  min-height: 200px;
  display: block;
}

.tbody-upcoming{
  max-height: 300px;
  overflow-y: scroll;
  min-height: 200px;
  display: block;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #0d3b6d ; 
  border-radius: 5px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background-clip: content-box;
  background: #ec7424 ; 
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0d3b6d ;

}

.parts-body-upcoming{
  display: table;
}

/*Carousel*/
.MuiPaper-root{
  margin-bottom: 10px;
}

.MuiButtonBase-root{
  background-color:#042d5a!important ;
  color:white!important
}

.MuiAccordionDetails-root,.MuiTypography-root{
  width: 100%;
}

.MuiTypography-root img:hover{
  transform: scale(2.5);
  z-index: 1;
  transition: 1s;
  position: absolute;
}

/* Footer */
.footer-homepage{
  padding: 7px 7px 0;
  margin-top: 7px;
  background-color:rgb(80, 78, 78);
  color:white;
  border-top:1px solid rgb(80, 78, 78) ;
  bottom: 0%;
  width: 100%;
}

.footer-back{
  padding: 7px 7px;
  margin-top: 7px;
  margin-inline: auto;
  background-color: white;
  border-top:1px solid white;
  font-size: 15px;
  bottom: 0%;
  width: 97%;
  color: rgb(13, 59, 109);
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */
@media (max-width: 992px) {
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
  }
  .navbar-collapse {
    flex-basis: 0;
  }
  .MuiTypography-root .row {
    font-size: 14px !important;
  }
  .navbar-title {
    font-size: 30px;
  }

  .navbar-text .link {
    padding-right: 5px;
  }
  .navbar-img {
    width: 120px;
    height: 50px;
    margin-left: 20px;
    margin-top: 5px;
  }
  .logindiv {
    width: 60%;
  }
}

@media (max-width: 768px) {
  #sidebar.active {
    margin-left: 0;
  }

  .home-page {
    display: block !important;
  }

  .number-parts {
    width: 98% !important;
    margin-inline: auto !important;
    margin-bottom: 10px;
  }

  #sidebar,
  .open-close-button,.menu {
    display: none;
  }

  .dropdown-responsive {
    display: block;
  }

  .open-close-button-responsive {
    margin-top: 0;
    color: white;
    background: #0d3b6d;
    height: 40px;
  }

  .open-close-button-responsive:hover {
    color: white;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    right: 0;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .dropdown-content a {
    color: #0d3b6d;
    padding: 12px 16px;
    display: block;
  }

  .dropdown-content a:hover {
    background-color: #0d3b6d;
    color: white;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  .dropdown-toggle {
    cursor: pointer;
    background-color: #f9f9f9 !important;
  }

  .dropdown-toggle:hover {
    background-color: #0d3b6d !important;
  }
}

@media (max-width: 500px) {
  .number-parts {
    font-size: 12px !important;
  }
  .parts-header,.btn-primary,.padding-div-back {
    font-size: 13px;
  }

  .MuiAccordionDetails-root {
    padding-inline: 0px !important ;
  }

  .MuiTypography-root .row,.navbar-text {
    font-size: 10px !important;
  }
  .MuiTypography-root .row,.navbar-text .link i {
    font-size: 13px !important;
    margin-left: 5px;
  }

  .navbar-title {
    font-size: 15px;
  }

  .navbar-text .link {
    padding-right: 5px;
  }
  .navbar-img {
    width: 90px;
    height: 35px;
    margin-left: 10px;
    margin-top: 13px;
  }
  .navbar-text img {
    margin-top: 6px;
    width: 40px;
    height: 40px;
  }
  .back-body label {
    text-align: left !important;
  }

  .prtl-span {
    font-size: 22px;
  }

  .edit-button,
  .delete-button,
  .success-button,
  .detail-button {
    font-size: 13px;
    margin-bottom: 1px;
  }

  .dataTables_length,
  #example1_filter {
    width: 100%;
  }

  .dataTable td {
    text-align: center;
  }
}
